<template>
    <loading loader="spinner"
    :active="isLoading"
    :can-cancel="true"
    :is-full-page="true"></loading>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">報表管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <!-- <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form class="">
          <div class="flex fle-col xl:flex-row items-center">
            <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
              <div class="p-1 mr-3 flex items-center">
                <div class="mr-2">年度</div>
                  <vxe-input class="w-36" v-model="yearFilter" clearable placeholder="年度" type="text">
                  </vxe-input>
                </div>
            </div>
            <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
              <div class="p-1 mr-3 flex items-center">
                <div class="mr-2">加權</div>
                  <vxe-input class="w-36" v-model="weighting" clearable placeholder="加權" type="text">
                  </vxe-input>
                </div>
            </div>
            <!-- <div class="mt-2 xl:mt-0">
              <button type="button" @click="search"
                class="button w-full sm:w-16 bg-theme-1 text-white">
                搜尋
              </button>
            </div> -->
            </div>
          </form>
      </div>
      <div class="mt-3 xl:flex sm:mr-auto">
        <div v-for="item in ReportList" :key="item.Type" class="mt-2 ml-2 xl:mt-0">
          <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white" @click="exportReport(item)">
            {{ item.Name }}
          </button>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { defineComponent, ref, Condition, Operator, onMounted } from '@cloudfun/core'
import VXETable from 'vxe-table'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    loading: VueLoading,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const isLoading = ref(false);
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;
    const checkPermissions = (key: string) => {
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canImport = checkPermissions("Administrator");

    const exportReport = async (item: any) => {
      console.log('exportReport click');
      try {
        isLoading.value = true;
        console.log(item);
        if(item) {
          console.log('if(item) true');
          // console.log(startDateFilter.value, endDateFilter.value, exDateFilter.value, CustomerId.value, yearFilter.value);
          var url = `${item.Url}?yearStr=${yearFilter.value ?? ''}&weighting=${weighting.value ?? ''}`
          window.open(url);
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "錯誤的報表網址",
          });
        }
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const startDateFilter = ref<Date>();
    const endDateFilter = ref<Date>();
      const exDateFilter = ref<Date>();
    const CustomerId = ref(null);
    const EmployeeId = ref(null);
    const yearFilter = ref(null);
    const weighting = ref(null);
    var ReportList = ref<any[]>([]);
    var list = ref<any[]>([
      { Name: '結算表', Rule: 1, Url: 'https://report.imp.cloudfun.app/api/ReportView/PerformanceEvaluationScoresReportView', Type: 1 },
    ]);

    onMounted(() => {
      list.value.forEach((e: any) => {
        ReportList.value.push(e);
      })
      console.log(ReportList.value)
    }) 

    const search = async() => {
      // 參數 startDate endDate CustomerId EmployeeId
      try {
        console.log('search');
        isLoading.value = true;
        ReportList.value = [];
        list.value.forEach((e: any) => {
          // 權限比對 @blur="search" 
          //console.log(startDateFilter.value, endDateFilter.value, exDateFilter.value, CustomerId.value, yearFilter.value);
          e.Url = `https://report.imp.cloudfun.app/api/ReportView/PerformanceEvaluationScoresReportView?yearStr=${yearFilter.value ?? ''}&weighting=${yearFilter.value ?? ''}`
          ReportList.value.push(e);
        })
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }      
    }

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("customer/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const employeeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("employee/find", value), // eslint-disable-line
        query: (params: any) => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    return {
      grid,
      model,
      canImport,
      isLoading,
      exportReport,
      endDateFilter,
      startDateFilter,
      exDateFilter,
      yearFilter,
      search,
      customerSelectorOptions,
      CustomerId,
      employeeSelectorOptions,
      EmployeeId,
      ReportList,
      weighting
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
